import { render, staticRenderFns } from "./AddOption.vue?vue&type=template&id=4d358104&scoped=true"
import script from "./AddOption.vue?vue&type=script&lang=ts"
export * from "./AddOption.vue?vue&type=script&lang=ts"
import style0 from "./AddOption.vue?vue&type=style&index=0&id=4d358104&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d358104",
  null
  
)

export default component.exports