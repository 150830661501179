
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import {
  Model,
  Options,
  Schema,
} from '@/types/vjsf';
import { Metadata } from '@/types/ListTypes';

@Component({
  name: 'AddOption',
  components: {
    VJsfForm: () => import('@/components/commonComponents/VJsfForm.vue'),
  },
  data: () => ({
    formModel: {} as Model,
  }),
})
export default class AddOption extends Vue {
  @Prop({ required: true })
  private readonly model!: Model;

  @Prop({ required: true })
  private readonly schema!: Schema;

  @Prop({ required: true })
  private readonly options!: Options;

  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  protected readonly dossierId!: number;

  @Prop()
  private readonly metadata: Metadata|undefined;

  private valid = true;

  private loading = false;

  protected mounted(): void {
    this.$data.formModel = { ...this.model };
  }

  private submitForm(): void {
    this.loading = true;

    const variables = {
      blockId: this.blockId,
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      data: this.$data.formModel,
    };

    import('@/graphql/operations/dossier-type-list-quick-add-form-by-block-id')
      .then(({ MUTATION: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        this.$emit('rerender', { id: response.data.quickAddForm.model.id });
        this.$data.formModel = { ...this.model };
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
